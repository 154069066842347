import styled from "styled-components";

export const ArrowContainer = styled.div<{
  readonly dir: string;
}>`
  display: flex;
  direction: ${({ dir }) => dir};
`;

export const ArrowBtn = styled.div<{
  readonly isDisabled?: boolean;
  readonly direction?: string;
}>`
  line-height: 0;
  transform: ${({ direction }) => (direction == "left" ? " rotate(180deg)" : "none")};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.2 : 1)};
  transition: 0.3s ease opacity;
  padding: 0px 5px;
`;

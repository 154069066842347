import mixpanel from "mixpanel-browser";

let trackingInitialized = false;

function source(): string {
  return 0 === document.referrer.search("https?://(.*)google.([^/?]*)")
    ? "Google"
    : 0 === document.referrer.search("https?://(.*)facebook.([^/?]*)")
    ? "Facebook"
    : 0 === document.referrer.search("https?://(.*)instagram.([^/?]*)")
    ? "Instagram"
    : 0 === document.referrer.search("https?://(.*)linkedin.([^/?]*)")
    ? "Linkedin"
    : 0 === document.referrer.search("https?://(.*)twitter.([^/?]*)")
    ? "Twitter"
    : 0 === document.referrer.search("https?://hakeem.heliumdoc.([^/?]*)")
    ? "Blog"
    : 0 === document.referrer.search("https?://(.*)heliumdoc.([^/?]*)")
    ? "Direct"
    : "Other";
}

// todo: type check the data parameter
export function initMixpanel(data) {
  if (typeof window !== "undefined" && !trackingInitialized) {
    let userAgent = navigator.userAgent;
    trackingInitialized = true;
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_SECRET_KEY, { debug: true });
    mixpanel.register_once({ Source: source() });
    mixpanel.people.set_once({ Source: source() });
    mixpanel.register({
      Locale: data.client_side_country || "qatar",
      Language: data.locale,
      "User Agent": userAgent,
    });
  }
}

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props, { send_immediately: true });
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;

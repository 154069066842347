import styled from 'styled-components';

type Props = {
  fill?: string;
  bgColor?: string;
  size?: number;
};

const TheSvg = styled.svg`
  line-height: 0;
  .add-transition {
    transition: 0.3s ease fill;
  }
`;
export default function CircleChevronIcon({ bgColor = '#2A3390', fill = '#FFF', size = 30 }: Props) {
  return (
    <TheSvg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle className="add-transition" cx="15" cy="15" r="15" fill={bgColor} />
      <path
        className="add-transition"
        d="M13.0813 21.9243L19.616 15.0012L13.0813 8.07812L11.5391 9.71197L16.5577 15.0012L11.5391 20.3181L13.0813 21.9243Z"
        fill={fill}
      />
    </TheSvg>
  );
}

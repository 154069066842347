import CircleChevronIcon from "TS_components/HomeHero/icons/CircleChevronIcon";
import { useRouter } from "next/router";
import { ArrowBtn, ArrowContainer } from "./SliderArrow.styles";
interface SliderArrowProps {
  next: () => void;
  prev: () => void;
}

export function LeftArrow({ prev }) {
  return (
    <ArrowBtn
      direction="left"
      isDisabled={false}
      onClick={() => {
        prev();
      }}
    >
      <CircleChevronIcon size={30} fill="#2A3390" bgColor="#E9EAF4" />
    </ArrowBtn>
  );
}

export function RightArrow({ next }) {
  return (
    <ArrowBtn
      direction="right"
      isDisabled={false}
      onClick={() => {
        next();
      }}
    >
      <CircleChevronIcon size={30} fill="#2A3390" bgColor="#E9EAF4" />
    </ArrowBtn>
  );
}
export const SliderArrow = ({ next, prev }: SliderArrowProps) => {
  const { locale } = useRouter();
  return (
    <ArrowContainer dir={locale == "ar" ? "rtl" : "ltr"}>
      <LeftArrow prev={prev}></LeftArrow>
      <RightArrow next={next}></RightArrow>
    </ArrowContainer>
  );
};

import { FC, useEffect, useRef, useState } from "react";
import {
  TimeslotsArrows,
  TimeslotsContainer,
  TimeslotsDateContainer,
  TimeslotsDateParent,
  TimeslotsDateSlider,
  TimeslotsDateTitle,
  TimeslotsNextAvailableDateButton,
  TimeslotsNextAvailableDateContainer,
  TimeslotsNoSlotsAvailableContainer,
  TimeslotsNoSlotsAvailableIcon,
  TimeslotsNoSlotsAvailableLink,
  TimeslotsNoSlotsAvailableTitle,
  TimeslotsNoSlotsAvailableTitleContainer,
  TimeslotsSlotsAvailableTitle,
  TimeslotsTime,
  TimeslotsTimeSpan,
  TimeslotsTimesContainer,
  TimeslotsTimesList,
  TimeslotsTimesParent,
  TimeslotsTimesTitle,
  TimeslotsTimesViewMore,
  TimeslotsTitle,
  TimeslotsTitleContainer,
} from "./Timeslots.styled";

import { SliderArrow } from "TS_components/common/SliderArrow";

import Loader from "@/components/Loader";
import useTranslation from "@/intl/useTranslation";
import { localize } from "@/utils";
import { _GetTimeslotsData } from "TS_Services/Booking";
import dayjs from "dayjs";
import { Mixpanel } from "lib/Mixpanel";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

interface TimeslotsProps {
  clinic: any;
  booking: any;
  selectTiming: (selectedTime) => void;
  selectAppointmentDate: (selectedDate) => void;
  visitType: string;
  bookings: any;
  discountVerified: boolean;
}

const Timeslots: FC<TimeslotsProps> = ({
  clinic,
  booking,
  selectTiming,
  selectAppointmentDate,
  visitType,
  bookings,
  discountVerified,
}) => {
  const { client_side_country, language_code } = useSelector(({ InitialDataS }: any) => InitialDataS);
  const { locale } = useRouter();
  const [chosenDate, setChosenDate] = useState("");
  const [chosenDateData, setChosenDateData] = useState([]);
  const [chosenTime, setChosenTime] = useState("");
  const [viewMoreSelected, setViewMoreSelected] = useState(false);
  const [dateToFetch, setDateToFetch] = useState(dayjs().format("YYYY-MM-DD"));
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialDataLoading, setIsInitialDataLoading] = useState(true);
  const [hideShowMore, setHideShowMore] = useState(false);
  const [timings, setTimings] = useState({});
  const [nextAvailableDate, setNextAvailableDate] = useState("");
  const [firstAvailableDate, setFirstAvailableDate] = useState("");
  const [isNoSlotAvailable, setIsNoSlotAvailable] = useState(false);
  const [nextAvailableLoadingText, setNextAvailableLoadingText] = useState("");
  const trackingProps = {
    "Visit Type": visitType,
    "Doctor Name": bookings.name_en,
    "Speciality Name": bookings.specialization.name_en,
    Date: chosenDate,
    "is Discount": discountVerified,
  };
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    maxWidth: 767,
  });
  let dateRef = useRef(dayjs().format("YYYY-MM-DD"));
  let loadingRef = useRef(false);
  let isInitialDateref = useRef(true);
  let isFallbackUsed = useRef(false);
  let nextAvailableLoadingTextIterator = useRef(0);

  const replaceDashesWithPluses = (str) => str.replace(/-/g, "+");

  useEffect(() => {
    let ele = document.getElementById("slotslist");
    const onScroll = (e) => {
      if (loadingRef.current) {
        return;
      }

      if (
        (Math.abs(ele.scrollWidth - ele.clientWidth - ele.scrollLeft) < 1 && locale == "en") ||
        (Math.abs(ele.scrollWidth - ele.clientWidth + ele.scrollLeft) < 1 && locale == "ar")
      ) {
        dateRef.current = dayjs(dateRef.current).add(6, "day").format("YYYY-MM-DD");
        loadingRef.current = true;
        setDateToFetch(dateRef.current);
      }
    };

    ele?.removeEventListener("scroll", onScroll);
    ele?.addEventListener("scroll", onScroll);
    return () => ele?.removeEventListener("scroll", onScroll);
  }, [isInitialDataLoading]);

  useEffect(async () => {
    loadingRef.current = true;

    if (isFallbackUsed.current) {
      var loadingMessages = ["Searching for next availability", "Almost there", "A few seconds left"];
      setNextAvailableLoadingText(loadingMessages[0]);
      nextAvailableLoadingTextIterator.current++;
      setInterval(function () {
        var newText = loadingMessages[nextAvailableLoadingTextIterator.current++ % loadingMessages.length];
        setNextAvailableLoadingText(newText);
      }, 10 * 1000);
    }

    setIsLoading(true);
    let timeslotsResponse = await _GetTimeslotsData({
      client_side_country,
      locale,
      bookingId: booking.id,
      clinicId: clinic.id,
      body: {
        date_from: dateToFetch,
        date_to: dateToFetch,
        use_fallback: isFallbackUsed.current,
      },
    });

    let mergedTimings = {};
    mergedTimings = { ...timings, ...timeslotsResponse.timings };

    let currentfirstAvailableDate = Object.keys(mergedTimings).filter((t) => mergedTimings[t].length)[0];

    if (isFallbackUsed.current && !currentfirstAvailableDate) {
      setIsInitialDataLoading(false);
      setIsNoSlotAvailable(true);
      Mixpanel.track("Booking Form, Instant | No Slots State Shown | Event", trackingProps);
      return;
    }

    let currentDate = dayjs();
    let ranges = {};
    let lastDate = Object.keys(timeslotsResponse.timings)[Object.keys(timeslotsResponse.timings).length - 1];

    while (currentDate.isBefore(lastDate) || currentDate.isSame(lastDate)) {
      ranges[currentDate.format("YYYY-MM-DD")] = [];
      currentDate = currentDate.add(1, "d");
    }

    setTimings({ ...ranges, ...mergedTimings });
    setNextAvailableDate(timeslotsResponse.next_available_date);
    if (timeslotsResponse.first_available) {
      setFirstAvailableDate(timeslotsResponse.first_available);
    }

    if (!chosenDate && currentfirstAvailableDate) {
      setChosenDate(currentfirstAvailableDate);
      setChosenDateData(mergedTimings[currentfirstAvailableDate]);
      dateRef.current = Object.keys(timeslotsResponse.timings)[0];
      if (!isInitialDateref.current) {
        loadingRef.current = true;
        let firstDateEle = document.getElementById("dateparent-" + dayjs(currentfirstAvailableDate).valueOf());
        document.getElementById("slotslist").scrollLeft = firstDateEle.offsetLeft - 69;
        isInitialDateref.current = true;
      }
    }

    if (isFallbackUsed.current) {
      Mixpanel.track("Booking Form, Instant | Fallback Algo Used | Event", trackingProps);
      isFallbackUsed.current = false;
      setNextAvailableLoadingText("");
    }

    setIsLoading(false);
    if (isInitialDataLoading) {
      setIsInitialDataLoading(false);
      Mixpanel.track("Booking Form, Instant | Initial Time Slots Loaded | Event", trackingProps);
    } else {
      Mixpanel.track("Booking Form, Instant | More Time Slots Loaded | Event ", trackingProps);
    }
    loadingRef.current = false;
  }, [dateToFetch]);

  useEffect(() => {
    let timeslotsListElem = document.getElementById("timeslots-list");
    if (timeslotsListElem && timeslotsListElem.scrollHeight > 110) {
      setHideShowMore(false);
    } else if (timeslotsListElem && timeslotsListElem.scrollHeight < 110) {
      setHideShowMore(true);
    }
  }, [timings, chosenDateData]);
  const nextBtn = () => {
    let ele = document.getElementById("slotslist");

    if (Math.abs(ele.scrollWidth - ele.clientWidth - ele.scrollLeft) < 1 && locale == "en" && !loadingRef.current) {
      dateRef.current = dayjs(dateRef.current).add(6, "day").format("YYYY-MM-DD");
      loadingRef.current = true;
      setDateToFetch(dateRef.current);
    } else {
      ele.scrollLeft += 360;
    }

    Mixpanel.track("Booking Form, Instant | Right Arrow Clicked | Button (desktop)", trackingProps);
  };

  const prevBtn = () => {
    let ele = document.getElementById("slotslist");

    if (Math.abs(ele.scrollWidth - ele.clientWidth + ele.scrollLeft) < 1 && locale == "ar" && !loadingRef.current) {
      dateRef.current = dayjs(dateRef.current).add(6, "day").format("YYYY-MM-DD");
      loadingRef.current = true;
      setDateToFetch(dateRef.current);
    } else {
      ele.scrollLeft -= 360;
    }
    Mixpanel.track("Booking Form, Instant | Left Arrow Clicked | Button (desktop)", trackingProps);
  };

  useEffect(() => {
    selectTiming(chosenTime);
  }, [chosenTime]);

  useEffect(() => {
    selectAppointmentDate(dayjs(chosenDate));
  }, [chosenDate]);

  const jumpToNextAvailable = () => {
    let dateToJump = "";
    let formattedFAD = dayjs(firstAvailableDate).format("YYYY-MM-DD");
    if (firstAvailableDate && formattedFAD != dateToFetch) {
      dateToJump = firstAvailableDate;
    } else {
      dateToJump = nextAvailableDate;
    }
    dateRef.current = dayjs(dateToJump).format("YYYY-MM-DD");
    loadingRef.current = true;
    isInitialDateref.current = false;
    isFallbackUsed.current = true;
    setDateToFetch(dateRef.current);
  };
  return (
    <TimeslotsContainer disabled={isNoSlotAvailable}>
      <TimeslotsTitleContainer>
        <TimeslotsTitle>{t("Choose your booking time")}</TimeslotsTitle>
        {!isMobile && !isNoSlotAvailable ? (
          <TimeslotsArrows>
            <SliderArrow
              next={() => {
                nextBtn();
              }}
              prev={() => {
                prevBtn();
              }}
            ></SliderArrow>
          </TimeslotsArrows>
        ) : (
          <></>
        )}
      </TimeslotsTitleContainer>
      {!isInitialDataLoading && !isNoSlotAvailable ? (
        <>
          <TimeslotsDateContainer>
            <TimeslotsDateSlider id="slotslist">
              {Object.keys(timings || {}).map((date, i) => {
                return (
                  <>
                    <TimeslotsDateParent
                      id={`dateparent-${dayjs(date).valueOf()}`}
                      onClick={() => {
                        setChosenDate(date);
                        setChosenDateData(timings[date]);
                        Mixpanel.track("Booking Form, Instant | Date selected | Button", trackingProps);
                      }}
                      selected={chosenDate == date}
                      disabled={!timings[date].length}
                    >
                      <TimeslotsDateTitle selected={chosenDate == date}>
                        {dayjs(date).format("ddd, DD MMM")}
                      </TimeslotsDateTitle>
                      {timings[date].length ? (
                        <TimeslotsSlotsAvailableTitle disabled={false} selected={chosenDate == date}>
                          {timings[date].length}{" "}
                          {timings[date].length == 1 ? t("slot available") : t("slots available")}
                        </TimeslotsSlotsAvailableTitle>
                      ) : (
                        <TimeslotsSlotsAvailableTitle disabled={true}>
                          {t("Not available")}
                        </TimeslotsSlotsAvailableTitle>
                      )}
                    </TimeslotsDateParent>
                    {Object.keys(timings).length == i + 1 && isLoading ? (
                      <Loader
                        transparent={true}
                        showInPlace={true}
                        containerStyle={{ maxHeight: "60px", height: "auto", margin: "0px 10px" }}
                      ></Loader>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </TimeslotsDateSlider>
          </TimeslotsDateContainer>
          {chosenDateData?.length ? (
            <TimeslotsTimesContainer>
              <TimeslotsTimesTitle>{dayjs(chosenDate).format("dddd, DD MMM")}</TimeslotsTimesTitle>
              <TimeslotsTimesParent>
                <TimeslotsTimesList id="timeslots-list" locale={locale} expanded={viewMoreSelected}>
                  {chosenDateData.map((time) => {
                    return (
                      <>
                        <TimeslotsTime
                          selected={chosenTime == time}
                          onClick={(e) => {
                            e.preventDefault();
                            setChosenTime(time);
                            Mixpanel.track("Booking Form, Instant | Time Chosen | Button", {
                              ...trackingProps,
                              ...{ Time: time },
                            });
                          }}
                        >
                          <TimeslotsTimeSpan>{time}</TimeslotsTimeSpan>
                        </TimeslotsTime>
                      </>
                    );
                  })}
                </TimeslotsTimesList>
              </TimeslotsTimesParent>
              {chosenDateData && !hideShowMore && (
                <TimeslotsTimesViewMore
                  id="slotslist"
                  onClick={() => {
                    setViewMoreSelected(!viewMoreSelected);
                    if (!viewMoreSelected) {
                      Mixpanel.track("Booking Form, Instant | Show More Times Clicked | Button", trackingProps);
                    } else {
                      Mixpanel.track("Booking Form, Instant | Show Less Times Clicked | Button", trackingProps);
                    }
                  }}
                >
                  {viewMoreSelected ? t("View less") : t("View more")}
                </TimeslotsTimesViewMore>
              )}
            </TimeslotsTimesContainer>
          ) : (
            <></>
          )}
          {!chosenDateData?.length ? (
            <TimeslotsNextAvailableDateContainer>
              {!isLoading ? (
                <TimeslotsNextAvailableDateButton
                  onClick={(e) => {
                    e.preventDefault();
                    jumpToNextAvailable();
                    Mixpanel.track("Booking Form, Instant | Show Next Availability Clicked | Button", trackingProps);
                  }}
                >
                  {t("Show next availability")}
                </TimeslotsNextAvailableDateButton>
              ) : (
                <Loader
                  loadingText={t(nextAvailableLoadingText) + "..."}
                  loaderTextStyle={{
                    fontSize: "12px",
                    color: "#282828",
                    marginTop: "5px",
                  }}
                  transparent={true}
                  showInPlace={true}
                  containerStyle={{ maxHeight: "60px", height: "auto", margin: "0px 10px" }}
                ></Loader>
              )}
            </TimeslotsNextAvailableDateContainer>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}

      {isInitialDataLoading ? (
        <Loader
          transparent={false}
          showInPlace={true}
          containerStyle={{
            backgroundColor: "#F9F9FC",
          }}
        ></Loader>
      ) : (
        <></>
      )}

      {isNoSlotAvailable ? (
        <TimeslotsNoSlotsAvailableContainer>
          <TimeslotsNoSlotsAvailableTitleContainer>
            <TimeslotsNoSlotsAvailableIcon></TimeslotsNoSlotsAvailableIcon>
            <TimeslotsNoSlotsAvailableTitle>{t("This doctor is currently unavailable")}</TimeslotsNoSlotsAvailableTitle>
          </TimeslotsNoSlotsAvailableTitleContainer>
          <TimeslotsNoSlotsAvailableLink
            href={`/${localize(
              `doctors/${replaceDashesWithPluses(bookings.specialization.slug)}`,
              client_side_country,
              language_code
            )}`}
          >
            {t("Search other")} {bookings.specialization.name}
          </TimeslotsNoSlotsAvailableLink>
        </TimeslotsNoSlotsAvailableContainer>
      ) : (
        <></>
      )}
    </TimeslotsContainer>
  );
};

export default Timeslots;

import NoAppointmentsCalendar from "public/next-images/no-appointment-calendar.svg";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

export const TimeslotsContainer = styled.div<{
  readonly disabled?: boolean;
}>`
  background-color: ${({ disabled, theme }) => (disabled ? theme.colors.greys.three : theme.colors.secondaryThree)};
  margin: 50px 0px;
  border-radius: 5px;
  max-width: 540px;
`;

export const TimeslotsTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
`;

export const TimeslotsTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

export const TimeslotsArrows = styled.div`
  display: flex;
`;

export const TimeslotsDateContainer = styled.div`
  margin-bottom: 20px;
  padding-bottom: 10px;
  .scroll-item {
    margin-left: 10px;
    display: flex;
    cursor: pointer;
  }

  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  > div {
    display: flex;
    overflow: auto;
  }
`;

export const TimeslotsDateSlider = styled.div`
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`;

export const TimeslotsDateParent = styled.div<{
  readonly selected?: boolean;
  readonly disabled?: boolean;
}>`
  background: ${({ selected, disabled }) => (selected ? "#1a99d6" : disabled ? "#DDDDDD" : "#F9F9FC")};
  padding: 7px 10px;
  border-radius: 5px;
  margin-left: 10px /* rtl: 0px */;
  margin-right: 0px /* rtl: 10px */;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-wrap: nowrap;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  border: ${({ selected, disabled, theme }) =>
    !selected && !disabled ? "1px solid " + theme.colors.greys.two : "none"};
`;

export const TimeslotsDateTitle = styled.div<{
  readonly selected?: boolean;
}>`
  font-weight: 700;
  font-size: 14px;
  color: ${({ selected }) => (selected ? "#fff" : "#000")};
  padding-bottom: 7px;
`;

export const TimeslotsSlotsAvailableTitle = styled.div<{
  readonly selected?: boolean;
  readonly disabled?: boolean;
}>`
  font-weight: 400;
  font-size: 12px;
  color: ${({ selected, disabled, theme }) =>
    disabled ? theme.colors.textDarkGray : selected ? theme.colors.white : theme.colors.primaryPurple};
`;

export const TimeslotsDateLoader = styled.div`
  margin: 0px 10px;
  display: flex;
`;

export const TimeslotsTimesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

export const TimeslotsTimesTitle = styled.div`
  font-weight: 600 /* rtl: 500 */;
  font-size: 14px /* rtl: 16px */;
`;

export const TimeslotsTimesParent = styled.div`
  display: flex;
  padding: 15px 0px 0px 0px;
`;

export const TimeslotsTimesList = styled.div<{
  readonly expanded?: boolean;
  readonly locale?: string;
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: ${({ expanded, locale }) => (expanded ? "inherit" : locale == "ar" ? "100px" : "105px")};
  overflow: hidden;
`;

export const TimeslotsTime = styled.button<{
  readonly selected?: boolean;
}>`
  all: unset;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  line-height: 15px !important;

  padding: 12px 8px;
  margin: 0px 6px 12px 6px !important;
  min-width: 72px;
  font-size: 12px !important /* rtl: 14px */;
  font-weight: 600 !important /* rtl: 500 */;
  border: 1px solid ${({ theme }) => theme.colors.primaryBlue};
  border-radius: 4px;
  text-decoration: none;
  background: ${({ theme, selected }) =>
    selected ? theme.colors.primaryBlue : theme.colors.secondaryThree} !important;
  color: ${({ theme, selected }) => (selected ? theme.colors.white : theme.colors.textDark)} !important;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryBlue} !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }

  &:selected {
    background: ${({ theme }) => theme.colors.primaryBlue} !important;
    color: white;
  }
`;

export const TimeslotsTimeSpan = styled.span`
  height: auto /* rtl: 12px */;
`;

export const TimeslotsTimesViewMore = styled.div`
  color: ${({ theme }) => theme.colors.generalLink};
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
`;

export const TimeslotsNextAvailableDateContainer = styled.div`
  padding: 0px 10px 15px 10px;
  display: flex;
  justify-content: center;
`;

export const TimeslotsNextAvailableDateButton = styled.button`
  all: unset;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primaryPurple} !important;
  background-color: ${({ theme }) => theme.colors.secondary};
  padding: 10px 40px;
  font-size: 14px !important;
  font-weight: 700 !important;
  ${media.max.sm`
    padding: 10px 88px;
  `}
`;

export const TimeslotsNoSlotsAvailableContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const TimeslotsNoSlotsAvailableTitleContainer = styled.div`
  margin-bottom: 30px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TimeslotsNoSlotsAvailableIcon = styled(NoAppointmentsCalendar)`
  margin-bottom: 10px;
`;

export const TimeslotsNoSlotsAvailableTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 30px;
`;

export const TimeslotsNoSlotsAvailableLink = styled.a`
  margin-bottom: 30px;
`;
